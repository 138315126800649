import React, { useState } from "react"
import styled from "styled-components"
import axios from "axios"
import { useTranslation } from "react-i18next"
import { containerSmallMaxWidth } from "../constants"
import { Controller, ErrorMessage, useForm } from "react-hook-form"
import ScrollTo from "react-scroll-into-view"
import { MEDIA_MIN_MEDIUM } from "../constants"
import Layout from "../components/Layout"
import Breadcrumbs from "../components/ui/Breadcrumbs"
import Input from "../components/ui/Input"
import Button from "../components/ui/Button"
import Loader from "../components/ui/Loader"
import Link from "../components/Link"

const Container = styled.div`
  max-width: ${containerSmallMaxWidth};
  margin: 0 auto;
  padding: 0 2.5rem 6rem;

  hr {
    margin: 3rem 0;
  }
`

const Header = styled.div`
  padding: 3rem 0 1rem;
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
`

const HelpCenterLink = styled(Link)`
  position: absolute;
  left: 0;
  bottom: -1.5rem;

  ${MEDIA_MIN_MEDIUM} {
    bottom: auto;
  }
`

const Title = styled.h1`
  text-align: center;
  justify-self: center;
`

const Text = styled.div`
  margin: 0 auto;
`

const Wrapper = styled.div`
  display: flex;
  flex-wrap: wrap;

  ${MEDIA_MIN_MEDIUM} {
    flex-wrap: nowrap;
  }
`

const Form = styled.form`
  order: 2;
  display: none;

  ${MEDIA_MIN_MEDIUM} {
    order: 1;
    margin-right: 5rem;
  }
`

const Field = styled(Input)`
  input {
    border: 0.1rem solid ${(p) => p.theme.colors.lightGrey};
    width: 100%;
    text-transform: initial;
  }
`

const SubmitButton = styled(Button)`
  background: ${(p) => p.theme.colors.secondary};
  margin-top: 1rem;

  &:hover {
    background: ${(p) => p.theme.colors.secondaryOpac};
  }
`

const ContactDetails = styled.div`
  display: flex;
  padding-top: 4rem;
  order: 1;
  width: 100%;

  a {
    color: ${(p) => p.theme.colors.black};
  }

  ${MEDIA_MIN_MEDIUM} {
    padding-top: 2rem;
    order: 2;
  }
`

const MessageContainer = styled.div`
  position: relative;
  margin-bottom: 1.5rem;
`

const Message = styled.textarea`
  border: 0.1rem solid ${(p) => p.theme.colors.lightGrey};
  width: 100%;
  height: 18rem;
  margin: 4rem 0 2rem;
  text-transform: initial;
  padding: 2rem;
`

const Validation = styled.div`
  color: ${(p) => p.theme.colors.errors};
  display: block;
  position: absolute;
  border-top: 0.2rem solid ${(p) => p.theme.colors.errors};
  width: 100%;
  padding-top: 0.4rem;
  letter-spacing: 0;
  font-size: 1.3rem;
  bottom: 0;
`

const Response = styled.div`
  border: 0.1rem solid ${(p) => p.theme.colors.lightGrey};
  padding: 1rem 2rem 1rem 5.8rem;
  max-width: 50rem;
  margin: 6rem auto 0;
  position: relative;
  display: block;

  ${MEDIA_MIN_MEDIUM} {
    margin: 3rem auto 0;
  }

  i {
    left: 2rem;
    font-size: 2.6rem;
    position: absolute;
    color: ${(p) =>
      p.type === "success" ? p.theme.colors.success : p.theme.colors.errors};
  }
`

const Attachment = styled.div`
  border: 0.1rem solid ${(p) => p.theme.colors.lightGrey};
  margin: 3.6rem 0 0;
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  padding: 1.5rem;

  ${MEDIA_MIN_MEDIUM} {
    flex-wrap: nowrap;
  }

  input {
    width: 0.01rem;
    height: 0.01rem;
    opacity: 0;
    overflow: hidden;
    position: absolute;
    z-index: -1;
  }

  label {
    padding: 1rem 1.5rem;
    text-transform: uppercase;
    font-size: 1.2rem;
    font-weight: 700;
    margin-right: 1rem;
    cursor: pointer;
    background: ${(p) => p.theme.colors.secondary};
    font-family: "AvenirNext-Bold", "sans-serif";

    &:hover {
      background: ${(p) => p.theme.colors.secondaryOpac};
    }
  }
`

const FileName = styled.div`
  width: 100%;
  margin-top: 1rem;

  ${MEDIA_MIN_MEDIUM} {
    width: auto;
    margin-top: 0;
  }
`

const SubjectSelect = styled.select`
  margin: 3.6rem 0 0;
  cursor: pointer;
  -webkit-appearance: none;
  -moz-appearance: none;
  background-position: 96% 50%;
  background-repeat: no-repeat;
  background-image: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAA4AAAAMCAYAAABSgIzaAAAAGXRFWHRTb2Z0d2FyZQBBZG9iZSBJbWFnZVJlYWR5ccllPAAAAyJpVFh0WE1MOmNvbS5hZG9iZS54bXAAAAAAADw/eHBhY2tldCBiZWdpbj0i77u/IiBpZD0iVzVNME1wQ2VoaUh6cmVTek5UY3prYzlkIj8+IDx4OnhtcG1ldGEgeG1sbnM6eD0iYWRvYmU6bnM6bWV0YS8iIHg6eG1wdGs9IkFkb2JlIFhNUCBDb3JlIDUuMC1jMDYwIDYxLjEzNDc3NywgMjAxMC8wMi8xMi0xNzozMjowMCAgICAgICAgIj4gPHJkZjpSREYgeG1sbnM6cmRmPSJodHRwOi8vd3d3LnczLm9yZy8xOTk5LzAyLzIyLXJkZi1zeW50YXgtbnMjIj4gPHJkZjpEZXNjcmlwdGlvbiByZGY6YWJvdXQ9IiIgeG1sbnM6eG1wPSJodHRwOi8vbnMuYWRvYmUuY29tL3hhcC8xLjAvIiB4bWxuczp4bXBNTT0iaHR0cDovL25zLmFkb2JlLmNvbS94YXAvMS4wL21tLyIgeG1sbnM6c3RSZWY9Imh0dHA6Ly9ucy5hZG9iZS5jb20veGFwLzEuMC9zVHlwZS9SZXNvdXJjZVJlZiMiIHhtcDpDcmVhdG9yVG9vbD0iQWRvYmUgUGhvdG9zaG9wIENTNSBNYWNpbnRvc2giIHhtcE1NOkluc3RhbmNlSUQ9InhtcC5paWQ6NDZFNDEwNjlGNzFEMTFFMkJEQ0VDRTM1N0RCMzMyMkIiIHhtcE1NOkRvY3VtZW50SUQ9InhtcC5kaWQ6NDZFNDEwNkFGNzFEMTFFMkJEQ0VDRTM1N0RCMzMyMkIiPiA8eG1wTU06RGVyaXZlZEZyb20gc3RSZWY6aW5zdGFuY2VJRD0ieG1wLmlpZDo0NkU0MTA2N0Y3MUQxMUUyQkRDRUNFMzU3REIzMzIyQiIgc3RSZWY6ZG9jdW1lbnRJRD0ieG1wLmRpZDo0NkU0MTA2OEY3MUQxMUUyQkRDRUNFMzU3REIzMzIyQiIvPiA8L3JkZjpEZXNjcmlwdGlvbj4gPC9yZGY6UkRGPiA8L3g6eG1wbWV0YT4gPD94cGFja2V0IGVuZD0iciI/PuGsgwQAAAA5SURBVHjaYvz;//z8DOYCJgUxAf42MQIzTk0D/M+KzkRGPoQSdykiKJrBGpOhgJFYTWNEIiEeAAAMAzNENEOH+do8AAAAASUVORK5CYII=);
`

const ContactPage = ({ pageContext }) => {
  const [isLoading, setIsLoading] = useState(false)
  const [response, setResponse] = useState(false)
  const [attachment, setAttachment] = useState(null)
  const { t } = useTranslation("translations")
  const { control, handleSubmit, errors } = useForm({
    mode: "onBlur",
    reValidateMode: "onBlur"
  })

  const onSubmit = (data) => {
    setIsLoading(true)

    const orderNumber = data.orderNumber
      ? `Order number: ${data.orderNumber}`
      : ""

    const message =
      `[${data.category.toUpperCase()}] You received a message from your website from: ${
        data.name
      } (${data.email})\n` +
      orderNumber +
      `\n\n ${data.message}`

    axios({
      method: "post",
      url: `https://`,
      data: {
        request: {
          requester: {
            name: data.name,
            email: data.email
          },
          subject: `[${data.category.toUpperCase()}] ${data.subject}`,
          custom_fields: [{ id: 360017461380, value: data.orderNumber }],
          comment: {
            body: message,
            uploads: [attachment?.token]
          }
        }
      }
    })
      .then((response) => {
        setResponse({ type: "success", message: response.data })
        setIsLoading(false)
      })
      .catch((error) => {
        setResponse({ type: "error", message: error.message })
        console.error("There was an error!", error)
        setIsLoading(false)
      })
  }

  const handleFileUpload = (e) => {
    setIsLoading(true)
    e.persist()
    const file = e.target.files[0]

    axios({
      method: "post",
      url: `https://xxxx.zendesk.com/api/v2/uploads.json?filename=${file.name}`,
      data: file,
      headers: {
        accept: "application/json",
        "Content-Type": "application/binary"
      }
    })
      .then((response) => {
        setAttachment(response.data.upload)
        setIsLoading(false)
      })
      .catch((error) => {
        setResponse({ type: "error", message: error.message })
        console.error("There was an error!", error)
        setIsLoading(false)
      })
  }

  return (
    <Layout
      meta={{ title: t("Contact us") }}
      invertedHeader
      pageContext={pageContext}
      slug={`contact`}
    >
      <Breadcrumbs>
        <Link to="/contact/">{t("Contact us")}</Link>
      </Breadcrumbs>
      <Container>
        <Loader isLoading={isLoading} />
        <Header>
          <HelpCenterLink to="/faq/" underline color="black">
            <i className="fal fa-long-arrow-left"></i> {t("FAQ")}
          </HelpCenterLink>
          <Title>{t("Contact us")}</Title>
        </Header>
        {response && (
          <Response type={response.type}>
            {response.type === "success" ? (
              <p>
                <i className="fal fa-check-circle"></i>
                <strong>{t("Thank you for your e-mail")}</strong>
                <br />
                {t("We will get back to you as soon as possible.")}
              </p>
            ) : (
              <p>
                <i className="fal fa-exclamation-triangle"></i>{" "}
                {t("Something went wrong, please try again later.")}
              </p>
            )}
          </Response>
        )}
        <Wrapper>
          <Form onSubmit={handleSubmit(onSubmit)}>
            <Controller
              as={Field}
              control={control}
              rules={{
                required: t("This is a required field")
              }}
              type="text"
              name="name"
              label={t("Name")}
              errors={errors}
            />
            <Controller
              as={Field}
              control={control}
              rules={{
                required: t("This is a required field"),
                pattern: {
                  value:
                    /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
                  message: t("Please supply a valid e-mail address")
                }
              }}
              type="email"
              name="email"
              label={t("E-mail")}
              errors={errors}
            />
            <Controller
              as={SubjectSelect}
              control={control}
              rules={{
                required: t("This is a required field")
              }}
              name="category"
              errors={errors}
            >
              <option value="">{t("Select department")}</option>
              <option value="return">{t("Return")}</option>
              <option value="exchange">{t("Exchange")}</option>
              <option value="complaint">{t("Complaint")}</option>
              <option value="delivery">{t("Delivery")}</option>
              <option value="payment">{t("Payment")}</option>
              <option value="other">{t("Other")}</option>
            </Controller>
            <Controller
              as={Field}
              control={control}
              rules={{
                required: t("This is a required field")
              }}
              type="text"
              name="subject"
              label={t("Subject")}
              errors={errors}
            />
            <Controller
              as={Field}
              control={control}
              type="text"
              name="orderNumber"
              label={t("Order number (Optional)")}
              errors={errors}
            />
            <Attachment>
              <input
                type="file"
                name="attachment"
                id="attachment"
                onChange={handleFileUpload}
              />
              <label htmlFor="attachment">{t("Add attachment")}</label>
              <FileName>
                {attachment
                  ? attachment.attachment?.file_name
                  : t("Choose file")}
              </FileName>
            </Attachment>
            <MessageContainer>
              <Controller
                as={Message}
                control={control}
                rules={{
                  required: t("This is a required field")
                }}
                type="textarea"
                name="message"
                errors={errors}
              />
              {errors && (
                <ErrorMessage errors={errors} name="message">
                  {({ message }) => <Validation>{message}</Validation>}
                </ErrorMessage>
              )}
            </MessageContainer>
            <ScrollTo selector=".top">
              <SubmitButton>{t("Submit")}</SubmitButton>
            </ScrollTo>
          </Form>

          <ContactDetails>
            <Text>
              <p>
                <strong>Rondure GmbH</strong>
              </p>
              <p>
                Talstr. 114
                <br />
                41516 Grevenbroich
                <br />
                Deutschland
              </p>
              <hr />
              <p>
                <strong>{t("Tel.")}:</strong> +49 2182 815080
                <br />
                <strong>{t("E-mail")}:</strong>{" "}
                <a href="mailto:info@rondure.de">info@rondure.de</a>
                <br />
                <strong>{t("Customer service")}:</strong>{" "}
                <a href="mailto:service@rondure.de">service@rondure.de</a>
              </p>
              <p>
                {t("All e-mails will be answered within 48 hours.")}
                <br />
                {t("Opening hours are from Monday to Friday from 9-17.")}
              </p>
              <hr />
              <p>
                <strong>Registergericht:</strong> Amtsgericht Mönchengladbach
                <br />
                <strong>Registernummer:</strong> HRB 20135
              </p>
              <p>
                <strong>Geschäftsführer:</strong> Rüdiger Matton
              </p>
              <p>
                <strong>
                  Umsatzsteuer-Identifikationsnummer gemäß § 27 a
                  Umsatzsteuergesetz:
                </strong>{" "}
                DE343901268
              </p>
              <p>
                <strong>
                  Plattform der EU-Kommission zur Online-Streitbeilegung:
                </strong>{" "}
                <a href="mailto:https://ec.europa.eu/odr">
                  https://ec.europa.eu/odr
                </a>
              </p>
              <p>
                Wir sind zur Teilnahme an einem Streitbeilegungsverfahren vor
                einer Verbraucherschlichtungsstelle weder verpflichtet noch
                bereit.
              </p>
            </Text>
          </ContactDetails>
        </Wrapper>
      </Container>
    </Layout>
  )
}

export default ContactPage
